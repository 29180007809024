import React, { Component, lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import NavigationBar from "./components/NavigationBar";

const Home = lazy(() => import("./routes/Home"));
const About = lazy(() => import("./routes/About"));
const Dev = lazy(() => import("./routes/Dev"));

class App extends Component {
  render() {
    return (
      <div>
        <NavigationBar />
        <Router>
          <Suspense fallback={<div>Route changing...</div>}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/dev" component={Dev} />
            </Switch>
          </Suspense>
        </Router>
      </div>
    );
  }
}

export default App;
