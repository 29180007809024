import AppBar from "@material-ui/core/AppBar";
import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import HomeIcon from "@material-ui/icons/Home";
import AboutIcon from "@material-ui/icons/Link";
import MenuIcon from "@material-ui/icons/Menu";
import React, { useState } from "react";

const useStyles = makeStyles(theme => ({
  list: {
    width: 250
  },
  root: {
    flexGrow: 1
  },
  menuButton: {
    marginRight: theme.spacing(2)
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block"
    }
  },

  inputRoot: {
    color: "inherit"
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 7),
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: 120,
      "&:focus": {
        width: 200
      }
    }
  }
}));

function ListItemLink(props) {
  return <ListItem button component="a" {...props} />;
}

export default function NavigationBar() {
  const classes = useStyles();
  const [drawerStatus, setDrawerStatus] = useState(false);

  const sideList = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={() => setDrawerStatus(true)}
      onKeyDown={() => setDrawerStatus(false)}
    >
      <List>
        <ListItem button>
          <ListItemLink href="/">
            <ListItemIcon>
              <HomeIcon></HomeIcon>
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemLink href="/about">
            <ListItemIcon>
              <AboutIcon></AboutIcon>
            </ListItemIcon>
            <ListItemText primary="About" />
          </ListItemLink>
        </ListItem>
        <ListItem button>
          <ListItemLink href="/dev">
            <ListItemIcon>
              <AboutIcon></AboutIcon>
            </ListItemIcon>
            <ListItemText primary="Dev" />
          </ListItemLink>
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <IconButton
            onClick={() => setDrawerStatus(true)}
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="open drawer"
          >
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            Peach Trees
          </Typography>
        </Toolbar>
        <Drawer open={drawerStatus} onClose={() => setDrawerStatus(false)}>
          {sideList()}
        </Drawer>
      </AppBar>
    </div>
  );
}
